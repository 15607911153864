import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import List from "@components/elements/list"
import Div from "@components/elements/div"
import Image from "@components/image"
import Anchor from "@components/elements/anchor"
import Grid from "@components/elements/grid"
import { processImages } from "@utils/process-images"
import Layout from "@components/layout"
import { useTheme } from "styled-components"
import Flex from "@components/elements/flex"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const TemplatePage = ({ data }) => {
  const images = processImages(data.images.edges)
  const { color, shadow } = useTheme()
  return (
    <Layout
      marginless
      fullWidth
      title="Custom Theme Add-On"
      description={`With our Custom Theme Power-Up, you can completely overhaul the look and feel of your dashboards.`}
    >
      <Div padding="10rem 0 6rem" background={color.blue100}>
        <Div textContainer>
          <Heading as="h2" margin="0 0 2rem">
            Build custom client dashboards
          </Heading>
          <Paragraph>
            Every client is different. Why not make their dashboards unique as
            well? With our Custom Theme Power-Up, you can completely overhaul
            the look and feel of your dashboards. From background colours to
            menu style to the way your dashboards load, with the Custom Theme
            Power-Up the sky’s the limit. We’ve put together the&nbsp;
            <b>Starter Packs</b> below to help you get up and running quickly.
            Check them out and download the CSS files to use in your own
            account!
          </Paragraph>
        </Div>
      </Div>
      <Flex gap="4rem" margin="6rem auto 8rem" container>
        <Grid columns="2fr 3fr" gap="4rem" gapSm="2rem" columnsMd="1fr">
          <Flex gap="1rem">
            <Heading as="h3">Starter Pack #1</Heading>
            <Paragraph>
              Looking to completely change the look and feel of Klipfolio? This
              theme does just that. From the background to the Klip outline
              colour, this custom theme completely changes the dashboard.
            </Paragraph>
            <Anchor
              fontSize="1.2rem"
              arrow
              link="https://website-assets.klipfolio.com/css-starter-pack-1.css"
            >
              Download this theme
            </Anchor>
          </Flex>
          <Image
            borderStyle={{
              boxShadow: shadow.dramatic,
            }}
            radius={0.5}
            file={images["b5e7fd85-3519-4aaa-a3ea-2639b1cf8b53"]}
          />
        </Grid>
        <Grid columns="2fr 3fr" gap="4rem" gapSm="2rem" columnsMd="1fr">
          <Flex gap="1rem">
            <Heading as="h3">Starter Pack #2</Heading>
            <Paragraph>
              A clean light theme with simple blue Klip outlines. This theme
              brings your dashboard to the next level with easy small changes.
            </Paragraph>
            <Anchor
              fontSize="1.2rem"
              arrow
              link="https://website-assets.klipfolio.com/css-starter-pack-2.css"
            >
              Download this theme
            </Anchor>
          </Flex>
          <Image
            borderStyle={{
              boxShadow: shadow.dramatic,
            }}
            radius={0.5}
            file={images["4f3ad9d3-d527-40ad-8c7b-378efec958bb"]}
          />
        </Grid>
        <Grid columns="2fr 3fr" gap="4rem" gapSm="2rem" columnsMd="1fr">
          <Flex gap="1rem">
            <Heading as="h3">Starter Pack #3</Heading>
            <Paragraph>
              Turn your light theme dashboards into custom dashboard with simple
              Klip outlining and custom brand colours.
            </Paragraph>
            <Anchor
              fontSize="1.2rem"
              arrow
              link="https://website-assets.klipfolio.com/css-starter-pack-3.css"
            >
              Download this theme
            </Anchor>
          </Flex>
          <Image
            borderStyle={{
              boxShadow: shadow.dramatic,
            }}
            radius={0.5}
            file={images["ee82c798-6b1c-4425-aec8-3e359f27552c"]}
          />
        </Grid>
        <Grid columns="2fr 3fr" gap="4rem" gapSm="2rem" columnsMd="1fr">
          <Flex gap="1rem">
            <Heading as="h3">Starter Pack #4</Heading>
            <Paragraph>
              Looking for a drastic change? This starter pack changes everything
              from the background colour to the shape of the Klips. Wow your
              clients with this custom theme.
            </Paragraph>
            <Anchor
              fontSize="1.2rem"
              arrow
              link="https://website-assets.klipfolio.com/css-starter-pack-4.css"
            >
              Download this theme
            </Anchor>
          </Flex>
          <Image
            borderStyle={{
              boxShadow: shadow.dramatic,
            }}
            radius={0.5}
            file={images["a7d3f6f0-13b8-4737-a9bc-14bf7af0bcdc"]}
          />
        </Grid>
        <Grid columns="2fr 3fr" gap="4rem" gapSm="2rem" columnsMd="1fr">
          <Flex gap="1rem">
            <Heading as="h3">Starter Pack #5</Heading>
            <Paragraph>
              Want a big change while keeping the light dashboard theme? This
              starter pack changes the look and feel of your Klips while keeping
              the crisp light background.
            </Paragraph>
            <Anchor
              fontSize="1.2rem"
              arrow
              link="https://website-assets.klipfolio.com/css-starter-pack-5.css"
            >
              Download this theme
            </Anchor>
          </Flex>
          <Image
            borderStyle={{
              boxShadow: shadow.dramatic,
            }}
            radius={0.5}
            file={images["65faf6b2-7a86-40f4-8677-9e916ae56ec0"]}
          />
        </Grid>
      </Flex>
      <Flex gap="1rem" margin="0 auto 6rem" textContainer>
        <Heading as="h2" margin="0 0 1rem">
          Getting started with our Custom Theme Power-Up
        </Heading>
        <Heading as="h3">Before you begin</Heading>
        <List gap="0" style={{ marginBottom: "1rem" }}>
          <li>
            Your account must be installed with an Power-Up to allow custom
            theming. If you’re interested in adding custom theming to your
            account, email us.
          </li>
          <li>
            You should have an intermediate understanding of using cascading
            style-sheets.
          </li>
        </List>
        <Heading as="h3">
          Setting up style sheets in the Klipfolio User Interface
        </Heading>
        <List as="ol" gap="0" style={{ marginBottom: "1rem" }}>
          <li>Log into your Klipfolio account.</li>
          <li>
            Click <strong>Account</strong>.
          </li>
          <li>
            Select the <strong>Settings</strong> tab and click on&nbsp;
            <strong>Branding</strong>.
          </li>
          <li>
            Click the <strong>Manage Theme CSS and Images</strong> button
            towards the bottom of the page.
          </li>
          <li>
            At <strong>Use Theme(s)</strong>, choose <strong>Custom</strong>.
          </li>
          <li>
            Click on <strong>Upload or Select CSS</strong> to upload a file from
            your computer or choose one you have already uploaded.
          </li>
          <li>
            Verify it is the correct file and click <strong>Finished</strong>.
          </li>
          <li>
            Select the file from the list and click <strong>Use File</strong>.
          </li>
          <li>
            Click <strong>Finished</strong> at the bottom of the page.
          </li>
        </List>
        <Heading as="h3">Themes and client accounts</Heading>
        <Paragraph>
          You can define a custom theme for each account. For accounts that have
          client accounts, each client account can have its own custom theme.
          Client accounts can also inherit the custom theme of a parent account.
        </Paragraph>
      </Flex>
      <KlipsEOPCta />
    </Layout>
  )
}

TemplatePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TemplatePage

export const pageQuery = graphql`
  query CustomThemePage {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "b5e7fd85-3519-4aaa-a3ea-2639b1cf8b53"
            "4f3ad9d3-d527-40ad-8c7b-378efec958bb"
            "ee82c798-6b1c-4425-aec8-3e359f27552c"
            "a7d3f6f0-13b8-4737-a9bc-14bf7af0bcdc"
            "65faf6b2-7a86-40f4-8677-9e916ae56ec0"
          ]
        }
      }
    ) {
      edges {
        node {
          ...imageData
        }
      }
    }
  }
`
